import React from "react";
import Layout from "../../components/Layout";
import ImageHero from "../../components/ImageHero";
import { graphql } from "gatsby";

export default (props) => {
  const {
    data: {
      file: { childImageSharp },
    },
  } = props;
  return (
    <Layout>
      <ImageHero
        heading={"Contact"}
        childImageSharp={childImageSharp}
        style={{ backgroundPosition: "center center" }}
        stylesTransparency={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      />
      <section className="section">
        <div className="container">
          <div className="content" style={{ textAlign: "center" }}>
            <h1>Thank you!</h1>
            <p>We will contact you as soon as possible.</p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ThanksPageTemplate {
    file(relativePath: { eq: "wimmic-satellite.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
